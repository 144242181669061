
import { EnumCols, PageBase } from '@/core/models/shared';
import { RelatorioService } from '@/core/services/financeiro';
import { Component } from 'vue-property-decorator';


@Component
export default class RelatorioReceita extends PageBase { 

    service = new RelatorioService();
    item = new RelatorioReceitaBoletoModel();
    overlay: boolean = false;

    snack = false;
    snackColor = "";
    snackText = "";

    filter:any = {};


  CopiarlinhaDigitavelBoleto(linhaDigitavelBoleto:string){
    const listener = (e: ClipboardEvent) => {
      e.clipboardData!.setData('text/plain', linhaDigitavelBoleto);
      e.preventDefault();
      document.removeEventListener('copy', listener);
    };

    this.save();
    document.addEventListener('copy', listener, { passive: true });
    //document.execCommand('copy');
    navigator.clipboard.writeText(linhaDigitavelBoleto).catch(err => {console.error("Erro ao copiar texto: ", err)});
  }

  VisualizarBoleto(urlBoleto){
    window.open(urlBoleto)
  }

  save() {
    this.snack = true;
    this.snackColor = "primary";
    this.snackText = "Copiado com sucesso!";
  }

   
mounted() {
    this.filter = this.$route.query;
    this.overlay = true;

    this.service.ListarRelatorioBoleto(this.filter.empreendimentoId, this.filter.boletoGerado, this.filter.pagamentoAutomatico, this.filter.clienteId, this.filter.tipoReceitaId, this.filter.dataVencimentoInicial, this.filter.dataVencimentoFinal, this.filter.empresaId, this.filter.sequenciaContrato, this.filter.dataCobrancaInicial, this.filter.dataCobrancaFinal, this.filter.situacaoParcelaId)
        .then(
            res => {
                this.item = res.data;
            }, 
            err=> {
                if (!err.response) {
                    this.$swal("Aviso", "Não foi possível acessar a API", "error");
                } 
                else {
                    this.$swal("Aviso", err.response.data, "error");
                }
            } 
        ).finally(() =>{
            this.overlay = false;
        }); 
    }
}
class RelatorioReceitaBoletoModel {
    public numeroContrato:number = 0; 
    public sequenciaContrato:number = 0; 
    public parcelaNumero:number = 0; 
    public clienteNome:string ="";
    public email:string ="";
    public telefone:string ="";
    public urlBoleto:string ="";
    public boletoGerado:boolean = false;
    public tipoReceita:string ="";
    public empreendimentoNome:string ="";
    public unidadeQuadra:string ="";
    public unidadeLote:string ="";
    public vencimento:string ="";
    public valor:number = 0; 
    public linhaDigitavelBoleto:string ="";
    public dataCobranca:string ="";
    public cobrancaEnviada:boolean = false; 
    public pagamentoAutomatico
    public empresaNome:string ="";
    public situacaoParcelaNome:string ="";
}

